import React, { createContext, useContext, useState, useEffect } from 'react';
import {
  alertApiRef,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { discoveryApiRef } from '@backstage/core-plugin-api';
import { TemplateRatingResponse } from '../types';
import { TemplateRatingApiClient } from '../api/TemplateRatingApiClient';

interface TemplateRatingsContextType {
  templateRatings: Record<string, TemplateRatingResponse>;
  loading: boolean;
}

const TemplateRatingsContext = createContext<
  TemplateRatingsContextType | undefined
>(undefined);

export const TemplateRatingsProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [templateRatings, setRatings] = useState<
    Record<string, TemplateRatingResponse>
  >({});
  const [loading, setLoading] = useState(true);
  const discoveryApi = useApi(discoveryApiRef);
  const identityApi = useApi(identityApiRef);
  const alertApi = useApi(alertApiRef);

  useEffect(() => {
    async function fetchTemplateRatings() {
      try {
        const ratingsApi = new TemplateRatingApiClient({
          discoveryApi,
          identityApi,
        });

        const data: TemplateRatingResponse[] =
          await ratingsApi.getAllTemplateRatings();
        const ratingsMap = data.reduce((acc, rating) => {
          acc[rating.templateName] = rating;
          return acc;
        }, {} as Record<string, TemplateRatingResponse>);

        setRatings(ratingsMap);
      } catch (error) {
        alertApi.post({
          message: `Error fetching template ratings: ${error}`,
          severity: 'error',
          display: 'transient',
        });
      } finally {
        setLoading(false);
      }
    }
    fetchTemplateRatings();
  }, [alertApi, discoveryApi, identityApi]);

  return (
    <TemplateRatingsContext.Provider value={{ templateRatings, loading }}>
      {children}
    </TemplateRatingsContext.Provider>
  );
};

export const useTemplateRatings = () => {
  const context = useContext(TemplateRatingsContext);
  if (!context) {
    throw new Error('useRatings must be used within a RatingsProvider');
  }
  return context;
};
