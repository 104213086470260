import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Link,
  Modal,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Rating from '@material-ui/lab/Rating';
import { alertApiRef, useApi } from '@backstage/core-plugin-api';
import { TemplateRatingApiClient } from './api/TemplateRatingApiClient';
import { TemplateRating } from './types';

interface ModalAllReviewsProps {
  templateName: string;
  open: boolean;
  onClose: () => void;
  ratingApi: TemplateRatingApiClient;
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: 'background.paper',
  borderRadius: 5,
  boxShadow: 24,
  p: 4,
};

export const ModalAllReviews = (props: ModalAllReviewsProps) => {
  const { templateName, open, onClose, ratingApi } = props;
  const alertApi = useApi(alertApiRef);
  const [reviews, setReviews] = useState<TemplateRating[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [expandedReview, setExpandedReview] = useState<number | null>(null);

  useEffect(() => {
    const fetchReviews = async () => {
      setLoading(true);
      try {
        const data = await ratingApi.getTemplateRatings(templateName);
        setReviews(data);
      } catch (error) {
        alertApi.post({
          message: `Failed to fetch reviews: ${error}`,
          severity: 'error',
          display: 'transient',
        });
        setReviews([]);
      } finally {
        setLoading(false);
      }
    };

    if (open) {
      fetchReviews();
    }
  }, [open, templateName, ratingApi, alertApi]);

  const transformUser = (userId: string) => {
    return userId.split('/')[1];
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5" component="h2" gutterBottom>
            {`Reviews for ${templateName}`}
          </Typography>
        </Box>
        <Divider style={{ marginBottom: '16px' }} />
        <Box sx={{ maxHeight: 400, overflow: 'auto' }}>
          {loading && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <CircularProgress />
            </Box>
          )}
          {!loading && reviews.length === 0 && (
            <Typography
              variant="body1"
              color="textSecondary"
              align="center"
              style={{ marginBottom: '16px' }}
            >
              No reviews available for this template.
            </Typography>
          )}
          {!loading &&
            reviews.length > 0 &&
            reviews.map((review, index) => (
              <React.Fragment key={index}>
                <Box
                  sx={{
                    py: 1,
                    mb: 1,
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <Rating value={review.rating} readOnly size="small" />
                    <Typography
                      variant="caption"
                      style={{ marginLeft: '4px', marginTop: '3px' }}
                    >
                      {review.rating.toFixed(1)}
                    </Typography>
                  </Box>
                  {review.comment && (
                    <Typography
                      variant="body2"
                      style={{
                        fontStyle: 'italic',
                        marginBottom: '4px',
                      }}
                    >
                      {`"${
                        expandedReview === index
                          ? review.comment
                          : review.comment.substring(0, 100)
                      }`}
                      {review.comment?.length > 100 &&
                        expandedReview !== index && (
                          <Link
                            component="button"
                            variant="body2"
                            onClick={() => setExpandedReview(index)}
                            style={{ textDecoration: 'underline' }}
                          >
                            ... (read more)
                          </Link>
                        )}
                      {`"`}
                    </Typography>
                  )}
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    style={{ display: 'block', textAlign: 'left' }}
                  >
                    {`By: ${transformUser(review.userId)}`}
                  </Typography>
                </Box>
                {index < reviews.length - 1 && <Divider />}
              </React.Fragment>
            ))}
        </Box>
        <Button onClick={onClose} variant="contained" color="primary" fullWidth>
          Close
        </Button>
      </Box>
    </Modal>
  );
};
