import {
  Box,
  Button,
  Divider,
  Modal,
  Typography,
  TextField,
} from '@material-ui/core';
import React, { useState } from 'react';
import Rating from '@material-ui/lab/Rating';
import {
  alertApiRef,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { TemplateRatingApiClient } from './api/TemplateRatingApiClient';

interface ModalSendReviewProps {
  templateName: string;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  ratingApi: TemplateRatingApiClient;
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 370,
  bgcolor: 'background.paper',
  borderRadius: 5,
  boxShadow: 24,
  p: 4,
};

export const ModalSendReview = (props: ModalSendReviewProps) => {
  const { templateName, open, onClose, onSuccess, ratingApi } = props;
  const [rating, setRating] = useState<number | null>(0.0);
  const [comment, setComment] = useState<string>('');

  const alertApi = useApi(alertApiRef);
  const identityApi = useApi(identityApiRef);

  const handleSubmit = async (
    ratingValue: number | null,
    reviewComment: string,
  ) => {
    if (!ratingValue || ratingValue === 0) {
      alertApi.post({
        message: `Please provide a rating before submitting your review.`,
        severity: 'warning',
        display: 'transient',
      });
      return;
    }

    try {
      const responseIdentity = await identityApi.getBackstageIdentity();
      const response = await ratingApi.sendTemplateRating({
        templateName,
        rating: ratingValue,
        comment: reviewComment,
        userId: responseIdentity.userEntityRef,
      });
      if (response?.status === 200) {
        alertApi.post({
          message: `Thank you for your review!`,
          severity: 'info',
          display: 'transient',
        });
        onSuccess();
      } else {
        alertApi.post({
          message: `Error rating template. Status: ${response.status} - ${response.statusText}`,
          severity: 'error',
          display: 'transient',
        });
      }
    } catch (error) {
      alertApi.post({
        message: `Error rating template. ${error}`,
        severity: 'error',
        display: 'transient',
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="review-modal-title"
      aria-describedby="review-modal-description"
    >
      <Box sx={modalStyle}>
        <Typography variant="h5" component="h2" gutterBottom>
          {`Leave a Review for ${templateName}`}
        </Typography>
        <Divider style={{ marginBottom: '16px' }} />
        <Box
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '100%',
          }}
          style={{ gap: '10px' }}
        >
          <Box display="flex" alignItems="center">
            <Typography variant="body2" style={{ marginRight: '8px' }}>
              Your Rating:
            </Typography>
            <Rating
              name="user-rating"
              value={rating}
              precision={0.5}
              onChange={(_, ratingValue) => setRating(ratingValue || 0)}
            />
          </Box>
          <Box>
            <Typography variant="body2" gutterBottom>
              Your Comment (optional):
            </Typography>
            <TextField
              multiline
              maxRows={4}
              placeholder="Write here..."
              value={comment}
              onChange={e => {
                if (e.target.value.length <= 300) {
                  setComment(e.target.value);
                }
              }}
              fullWidth
              variant="filled"
            />
            <Typography
              variant="caption"
              color="textSecondary"
              style={{
                display: 'block',
                textAlign: 'right',
                marginTop: '4px',
              }}
            >
              Max 300 characters
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '16px',
            }}
            style={{ gap: '10px' }}
          >
            <Button variant="outlined" color="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleSubmit(rating, comment);
                onClose();
              }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
