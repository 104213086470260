/*
 * Copyright 2022 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { RELATION_OWNED_BY } from '@backstage/catalog-model';
import { IconComponent, useAnalytics } from '@backstage/core-plugin-api';
import { getEntityRelations } from '@backstage/plugin-catalog-react';
// eslint-disable-next-line
import { TemplateEntityV1beta3 } from '@backstage/plugin-scaffolder-common';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Rating from '@material-ui/lab/Rating';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { useCallback, useState } from 'react';
import { CardHeader } from './CardHeader';
import { usePermission } from '@backstage/plugin-permission-react';
// eslint-disable-next-line
import { taskCreatePermission } from '@backstage/plugin-scaffolder-common/alpha';
import { TemplateCardContent } from './TemplateCardContent';
import { TemplateCardTags } from './TemplateCardTags';
import { TemplateCardLinks } from './TemplateCardLinks';
import { TemplateCardActions } from './TemplateCardActions';

import {
  discoveryApiRef,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { useTemplateRatings } from './context/TemplateRatingsContext';
import { TemplateRatingApiClient } from './api/TemplateRatingApiClient';
import { Typography, Link } from '@material-ui/core';
import { ModalAllReviews } from './ModalAllReviews';
import { ModalSendReview } from './ModalSendReview';

const useStyles = makeStyles<Theme>(() => ({
  actionContainer: { padding: '16px', flex: 1, alignItems: 'flex-end' },
}));

/**
 * The Props for the {@link TemplateCard} component
 * @alpha
 */
export interface TemplateCardProps {
  template: TemplateEntityV1beta3;
  additionalLinks?: {
    icon: IconComponent;
    text: string;
    url: string;
  }[];
  onSelected?: (template: TemplateEntityV1beta3) => void;
}

/**
 * The `TemplateCard` component that is rendered in a list for each template
 * @alpha
 */
export const TemplateCard = (props: TemplateCardProps) => {
  const { additionalLinks, onSelected, template } = props;
  const styles = useStyles();
  const analytics = useAnalytics();
  const ownedByRelations = getEntityRelations(template, RELATION_OWNED_BY);
  const hasTags = !!template.metadata.tags?.length;
  const hasLinks =
    !!additionalLinks?.length || !!template.metadata.links?.length;
  const displayDefaultDivider = !hasTags && !hasLinks;
  const discoveryApi = useApi(discoveryApiRef);
  const identityApi = useApi(identityApiRef);
  const ratingApi = new TemplateRatingApiClient({
    discoveryApi,
    identityApi,
  });

  const { templateRatings, loading } = useTemplateRatings();
  const templateName = template.metadata.name;

  const [rating, setRating] = useState(
    templateRatings[templateName]?.averageRating || 0.0,
  );
  const [totalReviews, setTotalReviews] = useState(
    templateRatings[templateName]?.totalRatings || 0,
  );
  const [openAllReviews, setOpenAllReviews] = useState(false);
  const [openLeaveReview, setOpenLeaveReview] = useState(false);

  const updateRating = async () => {
    const fetchRatingResponse = await ratingApi.getTemplateRating(templateName);
    setRating(fetchRatingResponse.averageRating);
    setTotalReviews(fetchRatingResponse.totalRatings);
  };

  const onRatingSucess = async () => {
    await updateRating();
    setOpenLeaveReview(false);
  };

  const { allowed: canCreateTask } = usePermission({
    permission: taskCreatePermission,
  });

  const handleChoose = useCallback(() => {
    analytics.captureEvent('click', `Template has been opened`);
    onSelected?.(template);
  }, [analytics, onSelected, template]);

  return (
    <>
      <Card>
        <CardHeader template={template} data-testid="template-card-header" />
        <CardContent>
          <Grid spacing={2} container data-testid="template-card-content">
            <TemplateCardContent template={template} />
            {displayDefaultDivider && (
              <Grid item xs={12}>
                <Divider data-testid="template-card-separator" />
              </Grid>
            )}
            {hasTags && <TemplateCardTags template={template} />}
            {!loading && (
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  marginBottom: '0px',
                  flexDirection: 'column',
                }}
              >
                <Box display="flex" alignItems="center">
                  <Rating
                    name={`rating-${template.metadata.name}`}
                    value={rating}
                    precision={0.5}
                    readOnly
                    style={{ marginBottom: '2px' }}
                  />
                  <Chip
                    color="primary"
                    style={{ marginLeft: '4px', marginBottom: '2px' }}
                    size="small"
                    label={rating.toFixed(1)}
                    key={rating.toFixed(1)}
                  />
                </Box>
                <Box display="flex" alignItems="center">
                  <Link
                    component="button"
                    variant="caption"
                    onClick={() => setOpenAllReviews(true)}
                    style={{
                      marginRight: '4px',
                      textDecoration: 'underline',
                    }}
                  >
                    See all reviews ({totalReviews})
                  </Link>
                  <Typography variant="body2" color="textSecondary">
                    |
                  </Typography>
                  <Link
                    component="button"
                    variant="caption"
                    onClick={() => setOpenLeaveReview(true)}
                    style={{
                      marginLeft: '4px',
                      textDecoration: 'underline',
                    }}
                  >
                    Leave a review
                  </Link>
                </Box>
              </Grid>
            )}

            {hasLinks && (
              <TemplateCardLinks
                template={template}
                additionalLinks={additionalLinks}
              />
            )}
          </Grid>
        </CardContent>
        <CardActions
          className={styles.actionContainer}
          data-testid="template-card-actions"
        >
          <TemplateCardActions
            canCreateTask={canCreateTask}
            handleChoose={handleChoose}
            ownedByRelations={ownedByRelations}
          />
        </CardActions>
      </Card>
      <ModalAllReviews
        templateName={template.metadata.name}
        open={openAllReviews}
        onClose={() => setOpenAllReviews(false)}
        ratingApi={ratingApi}
      />
      <ModalSendReview
        templateName={template.metadata.name}
        open={openLeaveReview}
        onClose={() => setOpenLeaveReview(false)}
        onSuccess={onRatingSucess}
        ratingApi={ratingApi}
      />
    </>
  );
};
