import { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api/*';
import { TemplateRating, TemplateRatingResponse } from '../types';

type TemplateRatingApiClientOptions = {
  discoveryApi: DiscoveryApi;
  identityApi: IdentityApi;
};

export class TemplateRatingApiClient {
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;

  constructor(opts: TemplateRatingApiClientOptions) {
    this.discoveryApi = opts.discoveryApi;
    this.identityApi = opts.identityApi;
  }

  private async customFetch(input: {
    path: string;
    method: string;
    data?: object;
  }): Promise<Response> {
    const baseApiUrl = await this.discoveryApi.getBaseUrl('template-rating');
    const { token } = await this.identityApi.getCredentials();
    const { path, method, data } = input;
    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      method,
      ...(data && method === 'POST' ? { body: JSON.stringify(data) } : {}),
    };
    const response = await fetch(`${baseApiUrl}${path}`, {
      ...options,
    });
    return response;
  }

  public async getTemplateRating(
    template: string,
  ): Promise<TemplateRatingResponse> {
    const response = await this.customFetch({
      path: `/rating/${template}`,
      method: 'GET',
    });
    const { data } = await response.json();
    return data;
  }

  public async sendTemplateRating(data: TemplateRating): Promise<Response> {
    const response = await this.customFetch({
      path: '/rating',
      method: 'POST',
      data,
    });
    return response;
  }

  public async getAllTemplateRatings(): Promise<TemplateRatingResponse[]> {
    const response = await this.customFetch({
      path: '/ratings',
      method: 'GET',
    });
    const { data } = await response.json();
    return data;
  }

  public async getTemplateRatings(template: string): Promise<TemplateRating[]> {
    const response = await this.customFetch({
      path: `/ratings/${template}`,
      method: 'GET',
    });
    const { data } = await response.json();
    return data;
  }
}
